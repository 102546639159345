"use strict";
import axios from 'axios';

const registerMostViewed = () => {
    axios.post('https://mv.larepublica.co/trends/lr', {
        postId: window.dataLayer[0].postId,
        postDate: window.dataLayer[0].postDateTime
    })
};

export const mostviewed = () => {
    if (window.dataLayer && window.dataLayer[0] && window.dataLayer[0].postId && !window.dataLayer[0].isSponsored) {
        registerMostViewed();
    }
};

export default mostviewed;