﻿let twitterPendingVideos = [];
let twitterIsInitialized = false;

export const processTwitter = (el) => {
  if (twitterIsInitialized) {
    insertTwitterPlayer(el);
  } else {
    if (!twitterPendingVideos.length) {
      insertTwitterScript();
    }
    twitterPendingVideos.push(el);
  }
};

const insertTwitterScript = () => {
  window.twttr = (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return t;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function (f) {
      t._e.push(f);
    };

    return t;
  })(document, "script", "twitter-wjs");
  window.twttr.ready((twttr) => {
    twitterIsInitialized = true;
    twitterPendingVideos.forEach((el) => {
      insertTwitterPlayer(el);
    });
  });
};

const insertTwitterPlayer = (el) => {
  window.twttr.widgets
    .createTweet(el.dataset.tweetId, el, {
      align: "center",
      lang: "es",
      dnt: true
    })
    .then(() => {
      el.className = "";
    });
};
