<template>
  <button
    type="button"
    @click="toggleFavorite"
    v-if="hasLoaded"
    class="save-button"
  >
    <i
      class="mr-2"
      v-bind:class="{
        'icon-turned_in_not': !isFavorite,
        'icon-close': isFavorite,
      }"
    ></i>
    {{ isFavorite ? "QUITAR DE FAVORITOS" : "GUARDAR" }}
  </button>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";
export const FavoriteAddButton = {
  store,
  props: {
    id: String,
    title: String,
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      hasLoaded: "hasLoaded",
      token: "token",
      favorites: "favorites",
    }),
    isFavorite() {
      const _self = this;
      return (
        this.favorites &&
        this.favorites.filter((q) => q.postId == _self.id).length > 0
      );
    },
  },
  methods: {
    ...mapActions("auth", {
      login: "login",
      addFavorite: "addFavorite",
      removeFavorite: "removeFavorite",
    }),
    showMessage: function (title, message, actions) {
      const _self = this;
      const h = this.$createElement;
      let elMsg = [h("p", message)];
      // Create the message
      if (actions && actions.length) {
        elMsg = elMsg.concat(
          h(
            "div",
            {
              class: ["text-right", "mb-0"],
            },
            actions.map((q) => {
              if (q.modal) {
                return h(
                  "a",
                  {
                    class: q.class,
                    on: {
                      click: function () {
                        _self.$bvToast.hide();
                        _self.$emit("modal");
                      },
                    },
                  },
                  q.title
                );
              } else if (q.href) {
                return h(
                  "a",
                  {
                    class: q.class,
                    attrs: {
                      href: q.href,
                      target: "_blank",
                      rel: "noopener",
                    },
                  },
                  q.title
                );
              } else if (q.action) {
                switch (q.action) {
                  case "login":
                    return h(
                      "a",
                      {
                        class: q.class,
                        on: {
                          click: function () {
                            _self.$bvToast.hide();
                            _self.login();
                          },
                        },
                      },
                      q.title
                    );
                    break;
                  default:
                    break;
                }
              }
            })
          )
        );
      }
      const vNodesMsg = h(
        "div",
        {
          class: ["mb-0"],
        },
        elMsg
      );
      this.$bvToast.toast([vNodesMsg], {
        title: title,
        toaster: "b-toaster-bottom-left",
        autoHideDelay: 100000,
        appendToast: true,
      });
    },
    toggleFavorite: function () {
      if (this.user) {
        if (!this.loading) {
          this.loading = true;
          const _self = this;
          _self.subscribeMessage = null;
          const axiosConfig = {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          };
          axios
            .post(
              "/api/lrplus/favorites",
              {
                id: this.id,
                title: this.title,
              },
              axiosConfig
            )
            .then(function (d) {
              if (d.data.success) {
                if (d.data.isFavorite) {
                  _self.addFavorite({
                    postId: _self.id,
                    postTitle: _self.title,
                  });
                } else {
                  _self.removeFavorite(_self.id);
                }
                _self.loading = false;
                _self.showMessage(
                  "Favoritos",
                  d.data.isFavorite
                    ? "Artículo guardado."
                    : "Artículo eliminado."
                );
              } else {
                _self.loading = false;
                switch (d.data.errorCode) {
                  case "limit":
                    if (_self.user.isSubscriber) {
                      _self.showMessage(
                        "Favoritos",
                        "Ha superado el límite de favoritos",
                        [
                          {
                            title: "Administrar Favoritos",
                            href: "https://mas.larepublica.co/articulos-guardados",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    } else {
                      _self.showMessage(
                        "Favoritos",
                        "Ha llegado al límite de favoritos, suscribase para ampliar el número de artículos que puede guardar.",
                        [
                          {
                            title: "Administrar Favoritos",
                            href: "https://mas.larepublica.co/articulos-guardados",
                            class: ["btn"],
                          },
                          {
                            title: "Suscribirse",
                            modal: "subscribe",
                            class: ["btn", "lr"],
                          },
                        ]
                      );
                    }
                    break;
                  case "auth":
                    _self.showMessage(
                      "Favoritos",
                      "Debe ingresar para agregar el artículo.",
                      [
                        {
                          title: "Ingresar",
                          class: ["btn", "lr"],
                          action: "login",
                        },
                      ]
                    );
                    break;
                  default:
                    _self.showMessage(
                      "Favoritos",
                      "Ha ocurrido un error, intente de nuevo más tarde."
                    );
                    break;
                }
              }
            })
            .catch(function (error) {
              _self.loading = false;
              _self.showMessage(
                "Favoritos",
                "Ha ocurrido un error, intente de nuevo más tarde."
              );
            });
        }
      } else {
        this.showMessage(
          "Favoritos",
          "Debe ingresar para guardar el artículo.",
          [
            {
              title: "Ingresar",
              class: ["btn", "lr"],
              action: "login",
            },
          ]
        );
      }
    },
  },
};

export default FavoriteAddButton;
</script>