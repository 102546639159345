﻿let dailyPendingVideos = [];
let dailyIsInitialized = window.dailymotion !== undefined && window.dailymotion.createPlayer !== undefined;

export const processDailymotion = (el) => {
  if (dailyIsInitialized) {
    insertDailymotionPlayer(el, 0);
  } else {
    if (!dailyPendingVideos.length) {
      insertDailymotionScript();
    }
    dailyPendingVideos.push(el);
  }
};

const insertDailymotionScript = () => {
  let tag = document.createElement("script");
  tag.src = "https://geo.dailymotion.com/libs/player/x4aj.js";
  tag.onload = () => {
    dailyIsInitialized = true;
    dailyPendingVideos.forEach((v) => {
      insertDailymotionPlayer(v, 0);
    });
  };
  let bodyTag = document.getElementsByTagName("body")[0];
  bodyTag.appendChild(tag);
};

const insertDailymotionPlayer = (el, retries) => {
  if (retries > 5) {
    console.log("Cannot load dailymotion");
    return;
  }
  if (dailymotion.createPlayer) {
    dailymotion.createPlayer(el.id, {
      video: el.dataset.videoId,
    });
  } else {
    retries++;
    setTimeout(() => {
      insertDailymotionPlayer(el, retries);
    }, 1000 * retries);
  }
};
