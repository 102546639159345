export const processMGID = (el) => {
    (function (d, s, id) {
        let js,
          fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.id = id;
        js.setAttribute("async", "");
        js.src = el.dataset.mgid;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "mgid-wjs");
};
