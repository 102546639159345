﻿export const processImg = (el) => {
  let newSrc = `${el.dataset.src}`;
  if (!el.dataset.src.includes("?")) {
    newSrc += `?`;
  } else {
    newSrc += `&`;
  }
  const oldSrc = el.src;
  el.src = newSrc;
  el.removeAttribute("data-src");
  if (el.dataset.srcset) {
    el.srcset = el.dataset.srcset;
    el.removeAttribute("data-srcset");
  }
  el.onerror = () => {
    el.src = oldSrc;
  };
};