export const scrollFixedMixin = {
    created: function () {
        let last_known_scroll_position = 0;
        let ticking = false;
        this.hasChanged = false;
        const _self = this;
        window.addEventListener('scroll', function (e) {
            last_known_scroll_position = window.scrollY;
            if (!ticking) {
                window.requestAnimationFrame(function () {
                    _self.scrolled(last_known_scroll_position);
                    ticking = false;
                });
            }
            ticking = true;
        });
    },
    mounted: function () {
        this.isVideoFixed = window.scrollY > 1067;
    },
    updated: function () {
        if (this.hasChanged) {
            window.dispatchEvent(new Event('textfill'));
            this.hasChanged = false;
        }
    },
    methods: {
        scrolled: function (y) {
            if (!this.isVideoClosed) {
                const a = this.isVideoFixed;
                this.isVideoFixed = y > 800;
                this.hasChanged = a != this.isVideoFixed;
            }
        }
    },
    data: function () {
        return {
            isVideoFixed: false,
            isVideoClosed: false
        };
    }
};