var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.posts
    ? _c(
        "div",
        {
          staticClass: "moduleNewsInterst",
          attrs: {
            "data-epica-module-name": _vm.epicaModule,
            "data-mrf-recirculation": _vm.epicaModule,
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row row-cols-3 news-brand mt-5" },
            _vm._l(_vm.posts, function (item) {
              return _c("div", { key: item.id, staticClass: "col mb-4" }, [
                _c("div", { staticClass: "row m-0 news H_img_title" }, [
                  _c("div", { staticClass: "col-5 pl-0 pr-3" }, [
                    _c(
                      "a",
                      { attrs: { href: item.postUrl } },
                      [_c("img-lr", { attrs: { image: item.principalImage } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-7 pl-0 pr-3" },
                    [
                      _c("a", {
                        staticClass: "kicker",
                        class: item.section.className,
                        attrs: { href: item.postUrl },
                        domProps: { innerHTML: _vm._s(item.header) },
                      }),
                      _vm._v(" "),
                      _c(
                        "text-fill",
                        {
                          attrs: {
                            "group-name": _vm.groupName,
                            "max-font-size": 35,
                          },
                        },
                        [
                          _c("a", {
                            attrs: { href: item.postUrl },
                            domProps: { innerHTML: _vm._s(item.title) },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      item.authorName
                        ? _c("div", { staticClass: "autor" }, [
                            _c("a", {
                              staticClass: "analistaName",
                              attrs: { href: item.authorUrl },
                              domProps: { innerHTML: _vm._s(item.authorName) },
                            }),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex mb-3" }, [
      _c("h3", { staticClass: "heading-title empresasSect topics" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://mas.larepublica.co/noticias",
              target: "_blank",
              rel: "noopener",
            },
          },
          [_vm._v("NOTICIAS DE SU INTERÉS")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex-grow-1 lineTitle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }