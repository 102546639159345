﻿let ytPendingVideos = [];
let ytIsInitialized = false;

export const processYT = (el) => {
  if (ytIsInitialized) {
    insertYTPlayer(el);
  } else {
    if (!ytPendingVideos.length) {
      insertYTScript();
      window.onYouTubeIframeAPIReady = function () {
        ytIsInitialized = true;
        ytPendingVideos.forEach((v) => {
          insertYTPlayer(v);
        });
      };
    }
    ytPendingVideos.push(el);
  }
};

const insertYTScript = () => {
  let tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  let firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
};

const insertYTPlayer = (el) => {
  const _ = new YT.Player(el, {
    height: el.dataset.height,
    width: el.dataset.width,
    videoId: el.dataset.videoId,
  });
};
