"use strict";

import Vue from "vue";

export const galleryMixin = {
  mounted: function () {
    const _self = this;
    _self.galleryAdUnit = window.galleryAdUnit;
    document.querySelectorAll(".post-wrapper figure").forEach((el, idx) => {
      if (!el.closest(".relatedOpinions")) {
        const img = el.getElementsByTagName("img")[0];
        const figcaption = el.getElementsByTagName("figcaption")[0];
        _self.gallery.push({
          src: img.dataset.original
            ? img.dataset.original
            : img.dataset.src
            ? img.dataset.src
            : img.src,
          alt: img.alt,
          title: img.title,
          description: img.dataset.description,
          caption: img.dataset.caption
            ? img.dataset.caption
            : figcaption
            ? figcaption.innerHTML
            : null,
        });
        el.addEventListener("click", function () {
          _self.openGallery(idx);
        });
        document.addEventListener("keyup", this.keyup);
      }
    });
    if (window.autoGallery) {
      _self.openGallery(0);
    }
  },
  data: function () {
    return {
      gallery: [],
      selectedIdx: -1,
      p: false,
      n: false,
      createdSlots: [],
    };
  },
  computed: {
    selectedImage: function () {
      const ret = this.selectedIdx > -1 ? this.gallery[this.selectedIdx] : null;
      if (ret) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      return ret;
    },
  },
  methods: {
    openGallery: function (idx) {
      const _self = this;
      this.selectedIdx = idx;
      Vue.nextTick(() => {
        var adunits = [_self.galleryAdUnit];
        var enAdUnits = [];
        for (var i = 0; i < adunits.length; i++) {
          var adId = adunits[i];
          var ad = ads[adId];
          if (ad) {
            if (ad.out) {
              _self.createdSlots.push(
                googletag
                  .defineOutOfPageSlot(ad.name, adId)
                  .addService(googletag.pubads())
              ); //.setCollapseEmptyDiv(true,true);
            } else {
              _self.createdSlots.push(
                googletag
                  .defineSlot(ad.name, ad.sizes, adId)
                  .addService(googletag.pubads())
                  .setCollapseEmptyDiv(true, true)
              );
            }
            enAdUnits.push(adId);
          }
        }
        for (var i = 0; i < adunits.length; i++) {
          googletag.display(_self.createdSlots[i]);
        }
      });
    },
    closeGallery: function () {
      const _self = this;
      googletag.destroySlots(_self.createdSlots);
      Vue.set(_self, "createdSlots", []);
      this.selectedIdx = -1;
    },
    keyup: function (event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      if (this.selectedImage) {
        if (event.keyCode == 37) {
          this.p = true;
          setTimeout(() => {
            this.p = false;
          }, 100);
          this.prev();
        }
        if (event.keyCode == 39) {
          this.n = true;
          setTimeout(() => {
            this.n = false;
          }, 100);
          this.next();
        }
        if (event.keyCode == 27) {
          this.closeGallery();
        }
      } else {
        if (event.keyCode == 71 && event.altKey) {
          this.openGallery(0);
        }
      }
    },
    next: function () {
      googletag.pubads().refresh(this.createdSlots);
      if (this.gallery.length > this.selectedIdx + 1) {
        this.selectedIdx++;
      } else {
        this.selectedIdx = 0;
      }
    },
    prev: function () {
      googletag.pubads().refresh(this.createdSlots);
      if (this.selectedIdx - 1 < 0) {
        this.selectedIdx = this.gallery.length - 1;
      } else {
        this.selectedIdx--;
      }
    },
  },
};
