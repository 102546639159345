﻿import { processFB } from "./lazy/fb";
import { processIframe } from "./lazy/iframe";
import { processImg } from "./lazy/img";
import { processIG } from "./lazy/instagram";
import { processTwitter } from "./lazy/twitter";
import { processYT } from "./lazy/yt";
import { processReddit } from "./lazy/reddit";
import { processDailymotion } from "./lazy/dailymotion";
import { processMGID } from "./lazy/mgid";

const intersectionObserver = new IntersectionObserver(
  function (entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        const lazyElement = entry.target;
        if (lazyElement.classList.contains("lazyload")) {
          lazyElement.classList.remove("lazyload");
          processLazy(lazyElement);
        }
        intersectionObserver.unobserve(lazyElement);
      }
    });
  },
  {
    rootMargin: "0px 0px 256px 0px",
  }
);

function processLazy(el) {
  if (el.nodeName === "IFRAME") {
    processIframe(el);
  } else if (el.nodeName === "IMG") {
    processImg(el);
  } else if (el.classList.contains("yt-video")) {
    processYT(el);
  } else if (el.classList.contains("dailymotion-video")) {
    processDailymotion(el);
  } else if (
    el.classList.contains("fb-post") ||
    el.classList.contains("fb-video")
  ) {
    processFB(el);
  } else if (el.classList.contains("tw")) {
    processTwitter(el);
  } else if (el.classList.contains("instagram-media-tmp")) {
    processIG(el);
  } else if (el.classList.contains("reddit-embed-bq")) {
    processReddit(el);
  } else if (el.dataset.mgid) {
    processMGID(el);
  }
}

export const initIntersections = () => {
  document.addEventListener("lrload", function () {
    let lazyElements = [].slice.call(document.querySelectorAll(".lazyload"));
    if (lazyElements.length) {
      lazyElements.forEach(function (lazyObj) {
        intersectionObserver.observe(lazyObj);
      });
    }
  });
};
