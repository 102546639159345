﻿// import instagramEmbed from './instagram-embed.js?url'

let igPendingVideos = [];
let igIsInitialized = false;

export const processIG = (el) => {
  if (igIsInitialized) {
    insertIGPlayer(el);
  } else {
    if (!igPendingVideos.length) {
      insertIGScript();
    }
    igPendingVideos.push(el);
  }
};

const insertIGScript = () => {
  const igOnLoad = () => {
    igIsInitialized = true;
    igPendingVideos.forEach((el) => {
      insertIGPlayer(el);
    });
  };
  (function (d, s, id, onload) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id) || window.instgrm) return;
    js = d.createElement(s);
    js.id = id;
    js.setAttribute("async", "");
    js.src = "https://s.lalr.co/__assets/global/vendor/instagram-embed.js";
    fjs.parentNode.insertBefore(js, fjs);
    js.onload = onload;
  })(document, "script", "instagram-wjs", igOnLoad);
};

const insertIGPlayer = (el) => {
  el.className = "instagram-media";
  window.instgrm.Embeds.process();
};
