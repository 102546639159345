﻿
let redditIsInitialized = false;

export const processReddit = (el) => {
  if (!redditIsInitialized) {
    insertRedditScript();
  }
};

const insertRedditScript = () => {
  const rdOnLoad = () => {
    redditIsInitialized = true;
  };
  (function (d, s, id, onload) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id) || window.reddit) return;
    js = d.createElement(s);
    js.id = id;
    js.setAttribute("async", "");
    js.src = "https://embed.reddit.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);
    js.onload = onload;
  })(document, "script", "reddit-wjs", rdOnLoad);
};
