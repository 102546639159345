﻿let fbPendingVideos = [];
let fbIsInitialized = false;

export const processFB = (el) => {
  if (fbIsInitialized) {
    insertFBPlayer(el);
  } else {
    if (!fbPendingVideos.length) {
      insertFBScript();
    }
    fbPendingVideos.push(el);
  }
};

const insertFBScript = () => {
  window.fbAsyncInit = function () {
    FB.init({ xfbml: false, version: "v3.2" });
    fbIsInitialized = true;
    fbPendingVideos.forEach((el) => {
      insertFBPlayer(el);
    });
  };
  (function (d, s, id) {
    let js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/es_CO/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

const insertFBPlayer = (el) => {
  const newNode = document.createElement('div');
  newNode.className = el.className;
  newNode.dataset.href = el.dataset.href;
  newNode.dataset.width = el.dataset.width;
  el.parentNode.insertBefore(newNode, el);
  el.parentNode.removeChild(el);
  FB.XFBML.parse(newNode.parentNode, () => {
    
  });
};
