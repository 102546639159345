var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasLoaded
    ? _c(
        "button",
        {
          staticClass: "save-button",
          attrs: { type: "button" },
          on: { click: _vm.toggleFavorite },
        },
        [
          _c("i", {
            staticClass: "mr-2",
            class: {
              "icon-turned_in_not": !_vm.isFavorite,
              "icon-close": _vm.isFavorite,
            },
          }),
          _vm._v(
            "\n  " +
              _vm._s(_vm.isFavorite ? "QUITAR DE FAVORITOS" : "GUARDAR") +
              "\n"
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }