<template>
  <div
    class="moduleNewsInterst"
    v-if="posts"
    :data-epica-module-name="epicaModule"
    :data-mrf-recirculation="epicaModule"
  >
    <div class="d-flex mb-3">
      <h3 class="heading-title empresasSect topics">
        <a
          href="https://mas.larepublica.co/noticias"
          target="_blank"
          rel="noopener"
          >NOTICIAS DE SU INTERÉS</a
        >
      </h3>
      <div class="flex-grow-1 lineTitle"></div>
    </div>
    <div class="row row-cols-3 news-brand mt-5">
      <div class="col mb-4" v-for="item in posts" :key="item.id">
        <div class="row m-0 news H_img_title">
          <div class="col-5 pl-0 pr-3">
            <a :href="item.postUrl">
              <img-lr :image="item.principalImage"></img-lr>
            </a>
          </div>
          <div class="col-7 pl-0 pr-3">
            <a
              :href="item.postUrl"
              class="kicker"
              :class="item.section.className"
              v-html="item.header"
            ></a>
            <text-fill v-cloak :group-name="groupName" :max-font-size="35">
              <a :href="item.postUrl" v-html="item.title"></a>
            </text-fill>
            <div class="autor" v-if="item.authorName">
              <a
                :href="item.authorUrl"
                class="analistaName"
                v-html="item.authorName"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import epica from "../../../features/epica";
import { TextFill } from "./../text-fill/TextFill.vue";
import ImgLr from "../img-lr/ImgLr.vue";
export const InterestNews = {
  components: { TextFill, ImgLr },
  props: {
    groupName: String,
    epicaModule: String,
  },
  created() {
    this.init();
  },
  updated: function () {
    epica();
  },
  methods: {
    init() {
      const _self = this;
      setTimeout(() => {
        if (window.epica && window.epica() && window.epica().anonymousId()) {
          _self.anonymousId = window.epica().anonymousId();
          axios
            .get(`/api/epica/suggest/${_self.anonymousId}`)
            .then(function (d) {
              _self.posts = d.data;
              setTimeout(function () {
                document.dispatchEvent(lrLoad);
              }, 200);
            });
        } else {
          this.init();
        }
      }, 100);
    },
  },
  data() {
    return {
      posts: null,
      anonymousId: "",
    };
  },
};
export default InterestNews;
</script>
