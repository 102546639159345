<template>
  <div v-if="open" class="modal-container">
    <div class="modal-open" @click.self="close">
      <div class="modal-dialog">
        <div v-if="!hasSubscriberId" class="modal">
          <form @submit.prevent="subscribe">
            <div class="header">
              <h3>Datos de la suscripción</h3>
              <button type="button" @click="close" class="close">x</button>
            </div>
            <div class="content">
              <p>
                Si ya es suscriptor de La República, ingrese su número de
                identificación.
              </p>
              <p>También puede comprar una nueva suscripción.</p>
              <input
                type="text"
                v-model="id"
                autocomplete="off"
                placeholder="Ingrese su identificación"
              />
              <p
                v-if="subscribeMessage"
                class="error"
                v-html="subscribeMessage"
              ></p>
            </div>
            <div class="actions">
              <a
                class="btn"
                href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
                target="_blank"
                rel="noopener"
                >Comprar suscripción</a
              >
              <button class="btn lr">Activar mi suscripción</button>
            </div>
          </form>
        </div>
        <div v-if="hasSubscriberId" class="modal">
          <div class="header">
            <h3>Suscripción Inactiva</h3>
            <button type="button" @click="close" class="close">x</button>
          </div>
          <div class="content">
            <p>
              Lo sentimos, el número de identificación relacionado con su cuenta
              no tiene una suscripción activa.
            </p>
            <p>
              Puede comprar su suscripción aquí o renovarla telefónicamente al.
            </p>
            <strong>(+57-1) 422 7600 Opción: 1-1-2</strong>
          </div>
          <div class="actions">
            <a
              class="btn lr"
              href="https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica"
              target="_blank"
              rel="noopener"
              >Comprar suscripción</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="backdrop"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import store from "../../store";
export const SubscribeModal = {
  store,
  props: {
    open: Boolean,
  },
  watch: {
    open: function (newVal, oldVal) {
      if (newVal) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
    },
  },
  computed: {
    ...mapGetters("auth", {
      user: "user",
      token: "token",
    }),
    hasSubscriberId() {
      return this.user && this.user.subscriberId;
    },
  },
  methods: {
    ...mapActions("auth", {
      subscribeUser: "subscribeUser",
    }),
    close: function () {
      this.$emit("closed");
    },
    subscribe: function (e) {
      if (this.id) {
        const _self = this;
        _self.subscribeMessage = null;
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        };
        axios
          .post(
            "/api/lrplus/subscribe",
            {
              identification: this.id,
            },
            axiosConfig
          )
          .then(function (d) {
            if (d.data.success) {
              _self.subscribeUser(_self.id);
              _self.$emit("successed");
            } else {
              _self.subscribeMessage = d.data.errorMessage;
            }
          });
      } else {
        const _self = this;
        _self.subscribeMessage = "El número de identificación es obligatorio";
        setTimeout(() => {
          _self.subscribeMessage = null;
        }, 3000);
      }
    },
  },
  data: function () {
    return {
      id: null,
      subscribeMessage: null,
    };
  },
};

export default SubscribeModal;
</script>
