var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.open
    ? _c("div", { staticClass: "modal-container" }, [
        _c(
          "div",
          {
            staticClass: "modal-open",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                return _vm.close.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              !_vm.hasSubscriberId
                ? _c("div", { staticClass: "modal" }, [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.subscribe.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "header" }, [
                          _c("h3", [_vm._v("Datos de la suscripción")]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "close",
                              attrs: { type: "button" },
                              on: { click: _vm.close },
                            },
                            [_vm._v("x")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _c("p", [
                            _vm._v(
                              "\n              Si ya es suscriptor de La República, ingrese su número de\n              identificación.\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "También puede comprar una nueva suscripción."
                            ),
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.id,
                                expression: "id",
                              },
                            ],
                            attrs: {
                              type: "text",
                              autocomplete: "off",
                              placeholder: "Ingrese su identificación",
                            },
                            domProps: { value: _vm.id },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.id = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.subscribeMessage
                            ? _c("p", {
                                staticClass: "error",
                                domProps: {
                                  innerHTML: _vm._s(_vm.subscribeMessage),
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm._m(0),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasSubscriberId
                ? _c("div", { staticClass: "modal" }, [
                    _c("div", { staticClass: "header" }, [
                      _c("h3", [_vm._v("Suscripción Inactiva")]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button" },
                          on: { click: _vm.close },
                        },
                        [_vm._v("x")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                    _vm._v(" "),
                    _vm._m(2),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "backdrop" }),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "actions" }, [
      _c(
        "a",
        {
          staticClass: "btn",
          attrs: {
            href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("Comprar suscripción")]
      ),
      _vm._v(" "),
      _c("button", { staticClass: "btn lr" }, [
        _vm._v("Activar mi suscripción"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("p", [
        _vm._v(
          "\n            Lo sentimos, el número de identificación relacionado con su cuenta\n            no tiene una suscripción activa.\n          "
        ),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n            Puede comprar su suscripción aquí o renovarla telefónicamente al.\n          "
        ),
      ]),
      _vm._v(" "),
      _c("strong", [_vm._v("(+57-1) 422 7600 Opción: 1-1-2")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "actions" }, [
      _c(
        "a",
        {
          staticClass: "btn lr",
          attrs: {
            href: "https://suscripciones.larepublica.co/?utm_source=landingLR&utm_medium=botonSuscripcion&utm_id=laRepublica",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("Comprar suscripción")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }